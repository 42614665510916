import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, CFText, Space, Text } from 'components'
import {
  appStore,
  googlePlay,
  whiteLogo,
  locationMap,
  restaurant,
  shellbg,
} from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, ContactUsElement } = this.props
    return (
      <CFView className={styles.container}>
        <div className={styles.row}>
          <CFLink
            href="https://goo.gl/maps/Q47uUbiDhgHkrJZZ7"
            className={styles.image}
          >
            <img src={locationMap} alt="Map" className={styles.locationMap} />
          </CFLink>
          <img
            src={restaurant}
            alt="Mr Ho Wonton House"
            className={styles.image}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <div className={styles.column}>
              <Text heading h2 white bold>
                CONTACT US
              </Text>
              <Space h2 />
              <CFView className={styles.contact}>{ContactUsElement}</CFView>
            </div>
            <Space width="4rem" height="4rem" />
            <div className={styles.column}>
              <Text heading h2 white bold>
                BUSINESS HOURS
              </Text>
              <Space h2 />
              <div className={styles.hours}>{HoursElement}</div>
            </div>
            <Space width="4rem" height="4rem" />
            <div className={styles.column}>
              <Text heading h2 white bold>
                DOWNLOAD APP
              </Text>
              <Space height="2rem" />
              <CFView w="25rem">
                <CFText h4 white content>
                  Mr Ho Wonton House is now taking online orders for pickup and
                  delivery. Download our app at:
                </CFText>
              </CFView>
              <Space height="2rem" />
              <CFView column justifyStart alignStart>
                <CFLink href="https://itunes.apple.com/ca/app/mr-ho-wonton-house/id1464637349">
                  <img
                    className={styles.mobile}
                    src={appStore}
                    alt="App Store"
                  />
                </CFLink>
                <Space height=".5rem" />
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.mrho">
                  <img
                    className={styles.mobile}
                    src={googlePlay}
                    alt="Goodle Play"
                  />
                </CFLink>
              </CFView>
            </div>
          </div>
        </div>
      </CFView>
    )
  }
}

const styles = {
  container: css({
    marginTop: '8rem',
    width: '100%',
    maxWidth: '130rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      marginTop: '4rem',
      padding: 0,
    },
  }),
  info: css({
    background: `url(${shellbg}) repeat`,
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: '100%',
    paddingTop: '50px',
  }),
  row: css({
    width: '100%',
    display: 'flex',
    maxWidth: '1100px',
    justifyContent: 'center',
    [MEDIA_QUERY.MD]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  contact: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '30rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: '3rem',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
      marginTop: 0,
      paddingBottom: '1rem',
    },
  }),
  whitelogo: css({
    objectFit: 'contain',
    [MEDIA_QUERY.MD]: {
      margin: '4rem',
    },
  }),
  locationMap: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),
  image: css({
    width: '50%',
    objectFit: 'cover',
    [MEDIA_QUERY.MD]: {
      width: '100%',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
}

export default Contact
