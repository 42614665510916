import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, Space, Text } from 'components'
import { promotions, mobilePromotion } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <CFView className={styles.container}>
        <img
          className={styles.promotions}
          src={promotions}
          alt="Mr Ho Promotions"
        />
        <img
          className={styles.promotionsMobile}
          src={mobilePromotion}
          alt="Mr Ho Promotions Mobile"
        />
      </CFView>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1400px',
    height: '100%',
    backgroundSize: 'cover',
  }),
  promotions: css({
    width: '100%',
    maxWidth: '1400px',
    objectFit: 'contain',
    [MEDIA_QUERY.LG]: {
      display: 'none',
    },
  }),
  promotionsMobile: css({
    display: 'none',
    [MEDIA_QUERY.LG]: {
      display: 'block',
      objectFit: 'contain',
      width: '100vw',
    },
  }),
}

// const styles = {
//   container: css({
//     marginTop: '8rem',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     width: '100%',
//     maxWidth: '130rem',
//     [MEDIA_QUERY.MD]: {
//       marginTop: '4rem',
//       overflowX: 'auto',
//     },
//   }),
//   row: css({
//     display: 'flex',
//     padding: '0 4rem 0 4rem',
//     justifyContent: 'space-between',
//     width: '100%',
//     [MEDIA_QUERY.MD]: {
//       padding: '0 1rem 0 1rem',
//       overflowX: 'auto',
//     },
//   }),
//   promoContainer: css({
//     width: '30%',
//     height: '50rem',
//     background: `url(${promobg}) no-repeat`,
//     backgroundSize: 'contain',
//     [MEDIA_QUERY.MD]: {
//       minWidth: '95vw',
//       backgroundPosition: '0 20%',
//     },
//   }),
//   promo: css({
//     padding: '7.5rem 4rem 4rem 4rem',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-end',
//     alignItems: 'center',
//   }),
//   header: css({
//     width: '80%',
//     objectFit: 'contain',
//   }),
//   image: css({
//     width: '90%',
//     borderRadius: '2rem',
//   }),
//   priceText: css({
//     fontFamily: 'georgia',
//   }),
// }

export default Promotions
