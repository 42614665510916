export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/facebook.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/instagram.png'
export const twitter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/twitter.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561915122/mrho/hero.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1558414235/mrho/logo.png'
export const promobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/promobg.png'
export const promotion1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1558416476/mrho/promotion3.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/promoHeader.png'
export const galleryHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/galleryHeader.png'
export const foodGallery =
  'https://dk1uf6jezg3x4.cloudfront.net/foodGallery2.webp'
export const shellbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/shellbg.jpg'
export const locationMap =
  'https://dk1uf6jezg3x4.cloudfront.net/locationMap.webp'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561914997/mrho/restaurant.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/cflogo.png'
export const whiteLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/whiteLogo.png'
export const paperbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1561914172/mrho/paperbg.jpg'
export const ctabanner =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/ctabanner.png'
export const redlogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1558413659/mrho/redlogo.png'
export const paperbg2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1561914172/mrho/paperbg2.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1561251838/mrho/rewardbg.jpg'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/redeemButton.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/rewardArrowRight.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/mrho/pointsMeter.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1561914998/mrho/rewardDefault.png'
export const promotions = 'https://dk1uf6jezg3x4.cloudfront.net/promo2.webp'
export const mobilePromotion =
  'https://dk1uf6jezg3x4.cloudfront.net/mobilePromotion2.webp'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1592538679/mrho/mobileHero.jpg'
export const mobileGallery =
  'https://dk1uf6jezg3x4.cloudfront.net/mobileGallery2.webp'
