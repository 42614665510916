import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView } from 'components'
import { foodGallery, galleryHeader, mobileGallery } from 'images'
import { MEDIA_QUERY } from 'styles'

class About extends Component {
  render() {
    return (
      // <div className={styles.container}>
      //   <CFView row justifyCenter alignCenter mb="4rem">
      //     <img className={styles.divider} src={galleryHeader} alt="divider" />
      //   </CFView>
      //   <img className={styles.gallery} src={foodGallery} alt="Food Gallery" />
      // </div>
      <CFView className={styles.container}>
        <img className={styles.gallery} src={foodGallery} alt="Mr Ho Gallery" />
        <img
          className={styles.galleryMobile}
          src={mobileGallery}
          alt="Mr Ho Gallery Mobile"
        />
      </CFView>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '1400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [MEDIA_QUERY.MD]: {
      padding: 0,
    },
  }),
  divider: css({
    width: '35%',
    objectFit: 'contain',
    [MEDIA_QUERY.LG]: {
      width: '80%',
    },
  }),
  gallery: css({
    width: '100%',
    maxWidth: '1400px',
    [MEDIA_QUERY.LG]: {
      display: 'none',
    },
  }),
  galleryMobile: css({
    display: 'none',
    [MEDIA_QUERY.LG]: {
      display: 'block',
      objectFit: 'contain',
      width: '100vw',
    },
  }),
}

export default About
