import React from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'
import { logo } from 'images'
import { COLOR, MEDIA_QUERY } from 'styles'

const CategoryItemView = ({ imageUrl, isSelected, nameFormatted }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {!!imageUrl ? (
          <img className={styles.image} src={imageUrl} alt="Category" />
        ) : (
          <img className={styles.logo} src={logo} alt="Recent Orders" />
        )}
        <div
          className={styles.imageCover}
          style={isSelected ? { backgroundColor: 'transparent' } : {}}
        />
      </div>
      <CFView fill center>
        <Text
          din
          bold
          color={isSelected ? COLOR.red : COLOR.white}
          center
          className={styles.text}
        >
          {nameFormatted}
        </Text>
      </CFView>
    </div>
  )
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    height: '20vw',
    width: '20vw',
    maxHeight: '21rem',
    maxWidth: '21rem',
    marginTop: '0.5rem',
    [MEDIA_QUERY.MD]: {
      height: '43vw',
      width: '43vw',
    },
  }),
  imageContainer: css({
    height: '75%',
    width: '95%',
    position: 'relative',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  image: css({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 2,
    boxShadow: '.2rem .2rem .4rem rgba(0,0,0,.8)',
  }),
  logo: css({
    height: '100%',
    width: '100%',
    padding: '1rem',
    objectFit: 'contain',
    position: 'absolute',
    zIndex: 2,
    boxShadow: '.2rem .2rem .4rem rgba(0,0,0,.8)',
  }),
  imageCover: css({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    [MEDIA_QUERY.MD]: {
      backgroundColor: 'transparent',
    },
  }),
  text: css({
    zIndex: 4,
    fontSize: 22,
    [MEDIA_QUERY.SM]: {
      fontSize: 18,
    },
  }),
}

export default CategoryItemView
