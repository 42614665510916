import React from 'react'
import { PaintedButtonView } from 'components'
import { COLOR } from 'styles'
import { css } from 'emotion'

export default () => (
  <PaintedButtonView label="ORDER NOW" className={styles.button} />
)

const styles = {
  button: css({
    backgroundColor: COLOR.red,
    color: 'white',
  }),
}
