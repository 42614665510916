import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Contact from './Contact'
import { paperbg } from 'images'

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
    } = this.props
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <div className={styles.bgContainer}>
          <Promotions />
          <About />
          <Contact
            HoursElement={HoursElement}
            LogoElement={LogoElement}
            ContactUsElement={ContactUsElement}
          />
        </div>
        <PageFooter />
      </section>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
  }),
  bgContainer: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'contain',
  }),
}

export default HomeView
