import React, { Component } from 'react'
import { css } from 'emotion'
import HomeHeader from './HomeHeader'
import { CFLink, CFView, Space, PaintedButtonView } from 'components'
import { hero, ctabanner, mobileHero } from 'images'
import { MEDIA_QUERY, COLOR } from 'styles'
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        <div className={styles.hero}>
          <img
            src={ctabanner}
            alt="Now Taking Online Orders!"
            className={styles.ctabanner}
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
          />
          <CFView className={styles.buttons}>
            <div className={styles.orderButton}>{OrderNowButtonElement}</div>
            <Space w2 />
            <div className={styles.downloadButton}>
              <CFLink href="http://onelink.to/d3gpvc">
                <PaintedButtonView
                  label="DOWNLOAD APP"
                  className={styles.button}
                />
              </CFLink>
            </div>
          </CFView>
        </div>
      </div>
    )
  }
  _handleOrderClick = () => {
    this.props.history.push('/menu')
  }
}
const styles = {
  container: css({
    height: '100%',
    width: '100%',
  }),
  hero: css({
    height: 'calc(100vh - 10rem)',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      background: `url(${mobileHero}) no-repeat`,
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
      backgroundAttachment: 'scroll',
    },
  }),
  ctabanner: css({
    position: 'absolute',
    top: '7%',
    right: '5%',
    width: '50%',
    objectFit: 'contain',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  buttons: css({
    display: 'flex',
    position: 'absolute',
    bottom: '18%',
    right: '10%',
    [MEDIA_QUERY.MD]: {
      bottom: '6%',
      right: '50%',
      transform: 'translateX(50%)',
      flexDirection: 'column',
    },
  }),
  button: css({
    backgroundColor: 'white',
    color: 'black',
    [MEDIA_QUERY.MD]: {
      marginTop: '1rem',
      backgroundColor: COLOR.red,
      color: 'white',
    },
  }),
  sushi: css({
    height: '70%',
    maxHeight: '70rem',
    objectFit: 'contain',
    [MEDIA_QUERY.MD]: {
      width: '100%',
    },
  }),
}

export default Hero
