import React from 'react'
import { CFLink, CFView, CFText, Text } from 'components'

export default ({ address, phoneNumber, email }) => (
  <CFView column w="250px">
    {address && (
      <CFView row justifyBetween w="100%">
        <CFView mb="2rem">
          <Text heading h4 white>
            Address
          </Text>
        </CFView>
        <CFView column justifyStart alignStart mb="2rem" ml="30px">
          <CFLink href="https://goo.gl/maps/Q47uUbiDhgHkrJZZ7">
            <CFText heading h4 white>
              {address}
            </CFText>
          </CFLink>
        </CFView>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row justifyBetween w="100%" mb="2rem">
        <CFView>
          <Text heading h4 white>
            Phone
          </Text>
        </CFView>
        <CFText
          column
          justifyStart
          alignStart
          heading
          h4
          white
          mb="2rem"
          ml="15px"
        >
          {phoneNumber}
        </CFText>
      </CFView>
    )}
    {email && (
      <CFView row justifyBetween w="100%">
        <CFView>
          <Text heading h4 white>
            Email
          </Text>
        </CFView>
        <CFLink href={`mailto:${email}`}>
          <Text heading h4 white>
            {email}
          </Text>
        </CFLink>
      </CFView>
    )}
  </CFView>
)
