import React from 'react'
import { css } from 'emotion'
import { CFView, Card, Container, Space } from 'components'
import { cftitle, codeFusionVideo } from 'images'
import { MEDIA_QUERY } from 'styles'

const AuthView = ({
  GoBackButtonElement,
  EmailInputElement,
  PasswordInputElement,
  NameInputElement,
  PhoneInputElement,
  NextButtonElement,
  LoginButtonElement,
  ForgotPasswordButtonElement,
}) => (
  <Container>
    <div className={styles.content}>
      <Card className={styles.border}>
        <div className={styles.logoContainer}>
          <video autoPlay muted loop className={styles.logo}>
            <source {...codeFusionVideo.webm} />
            <source {...codeFusionVideo.hevc} />
            <source {...codeFusionVideo.mp4} />
          </video>
          <img className={styles.title} src={cftitle} alt="codefusion" />
        </div>
        <div className={styles.form}>
          <div>{GoBackButtonElement}</div>
          <Space h2 />
          {EmailInputElement}
          <Space h1 />
          {PasswordInputElement}
          <Space h1 />
          {NameInputElement && <CFView mb="1rem">{NameInputElement}</CFView>}
          {PhoneInputElement && <CFView mb="1rem">{PhoneInputElement}</CFView>}
          {NextButtonElement}
          <Space h1 />
          {LoginButtonElement}
          <CFView mt="2rem">{ForgotPasswordButtonElement}</CFView>
        </div>
      </Card>
    </div>
  </Container>
)

const styles = {
  content: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 10rem)',
    color: 'white',
    padding: '2rem',
    [MEDIA_QUERY.SM]: {
      minHeight: 'calc(100vh - 13rem)',
    },
  }),
  border: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100rem',
    [MEDIA_QUERY.SM]: {
      width: '100%',
      flexDirection: 'column',
    },
  }),
  form: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    [MEDIA_QUERY.SM]: {
      marginTop: '2rem',
    },
  }),
  logoContainer: css({
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '30rem',
    padding: '2rem',
    marginRight: '4rem',
  }),
  title: css({
    width: '45rem',
    [MEDIA_QUERY.SM]: {
      width: '95%',
    },
  }),
  logo: css({
    height: '20rem',
    objectFit: 'contain',
    [MEDIA_QUERY.SM]: {
      height: '85%',
    },
  }),
}

export default AuthView
