import React, { Component } from 'react'
import { css } from 'emotion'
import { LocationSelect, CFSelect } from 'cf-web-app/components'
import { IconLink } from 'components'
import { shellbg, redlogo } from 'images'
import { dispatch } from 'cf-web-app/store'
import { MEDIA_QUERY } from 'styles'

class HomeHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={redlogo} alt="Mr Ho Wonton House" />
        <LocationSelect />
        <div className={styles.nav}>
          <nav className={styles.navContainer}>
            <IconLink
              label="MENU"
              to="/menu"
              icon="utensils"
              style={{ color: 'white' }}
            />
            <CFSelect selector={dispatch.user.getCartCount}>
              {cartCount => (
                <IconLink
                  label={`CART (${cartCount})`}
                  icon="shopping-cart"
                  to="/cart"
                  style={{ color: 'white' }}
                />
              )}
            </CFSelect>
            <CFSelect selector={dispatch.user.getIsLoggedIn}>
              {this.renderAuthLink}
            </CFSelect>
          </nav>
        </div>
      </div>
    )
  }
  renderAuthLink = isLoggedIn => {
    return isLoggedIn ? (
      <IconLink
        label="USER"
        to="/user"
        icon="user"
        style={{ color: 'white' }}
      />
    ) : (
      <IconLink
        label="LOGIN"
        icon="sign-in-alt"
        to="/auth/menu"
        style={{ color: 'white' }}
      />
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1rem',
  }),
  navContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6rem',
    width: '62rem',
    [MEDIA_QUERY.SM]: {
      width: '85%',
    },
  }),
  nav: css({
    marginTop: '1.5rem',
    background: `url(${shellbg}) repeat-x`,
    width: '100%',
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

export default HomeHeader
