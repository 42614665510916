import React from 'react'
import { css } from 'emotion'
import * as images from 'images'

const RewardsLeftArrowButtonView = ({ disabled }) => (
  <img
    className={styles.leftArrow}
    src={images.rewardArrowLeft}
    style={disabled ? { opacity: 0.5 } : {}}
    alt="Left Arrow"
  />
)

const styles = {
  leftArrow: css({
    position: 'absolute',
    top: '50%',
    left: '2rem',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:active': {
      transform: 'translateY(-1px)',
    },
  }),
}

export default RewardsLeftArrowButtonView
