import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'

class PaintedButtonView extends Component {
  render() {
    const { className, disabled, label } = this.props
    const classNames = css(
      disabled ? styles.disabled : styles.button,
      className
    )
    return (
      <CFView center className={classNames}>
        <Text content h2 bold disabled={disabled}>
          {label}
        </Text>
      </CFView>
    )
  }
}

const styles = {
  button: css({
    width: '25rem',
    height: '5rem',
    borderRadius: '1rem',
    background: '#171717',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  disabled: css({
    paddingLeft: '4rem',
    paddingRight: '4rem',
    height: '4rem',
    background: 'rgba(0,0,0,0.4)',
    cursor: 'pointer',
  }),
}

export default PaintedButtonView
